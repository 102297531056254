import oJs from '@/js/config.js';
const URL = {
	enterPriseList : 'admin/wechat.EnterPrise/getList', // 获取公司列表
	fastRegister : 'admin/wechat.Fastregister/fastRegisterWechatApp', // 提交快速注册请求
	// discard: 'admin/crm.clues/discard', // 废弃线索信息
}

/**
 * 弹出注册窗口
 */
export function register(param, that){
	getEnterPriseList(that);
	that.$refs.tableDrawer.dialogVisible = true;
}

/**
 * 获取企业列表
 * @param {Object} that
 */
export function getEnterPriseList(that){
	oJs.request(URL.enterPriseList, {limit:100}).then((res) => {
		that.$refs.tableDrawer.arrEnterPrise = res.data
	})
}

export default {
	/**
	 * 请求快速注册接口
	 * @param {Object} sId
	 */
	fastRegister(sId){
		return new Promise((resolve, recject) => {
			oJs.request(URL.fastRegister, {id:sId}).then(res => {
				resolve(true);
			})
		})
	}
}


