<template>
	<div>
		<el-dialog title="请选择要注册的企业" :visible.sync="dialogVisible" width="30%">
		  <el-select v-model="nChecked" placeholder="请选择企业">
		      <el-option v-for="item in arrEnterPrise" :key="item.id" :label="item.name" :value="item.id"></el-option>
		    </el-select>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="submit">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import oJs from '@/js/wechat.fastregister.js';
	export default{
		data() {
			return {
				dialogVisible: false, // 提示框是否展示
				arrEnterPrise :[],  // 企业信息
				nChecked: ''
			};
		},
		created() {
		},
		methods: {
			submit(){
				if (this.nChecked === ''){
					this.$message.error('请选择企业')
					return;
				}
				// 请求接口
				oJs.fastRegister(this.nChecked).then((res) => {
					this.dialogVisible = false;
				})
			}
		},
	}
</script>

<style>
</style>
