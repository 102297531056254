<template>
	<div>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="45%" >
			<el-table :data="tableData" style="width: 100%">
				<el-table-column prop="name" label="姓名" align="center">
				</el-table-column>
				<el-table-column prop="phone" label="电话" align="center">
				</el-table-column>
				<el-table-column prop="mark" label="备注" align="center">
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				tableData:[],
			};
		},
		created() {
		},
		methods: {
			
		},
	};
</script>

<style>
</style>
